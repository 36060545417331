import PostResponseResource from "common/resources/Post/PostResponseResource";
import { dateFnsLocal } from "components/materials/I18n/I18nStore";
import I18n from "components/materials/I18n";
import DefaultAvatar from "assets/images/default-avatar.svg";
import { format } from "date-fns";
import classes from "./classes.module.scss";
import Typography from "components/elements/Typography";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import classNames from "classnames";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { container } from "tsyringe";
import ProjectService from "services/ProjectService";

interface ForumHeaderProps {
	post: PostResponseResource;
}

const formatDate = (date: Date) => {
	return format(new Date(date), "PPPp", { locale: dateFnsLocal[I18n.getLocale()] });
};

const projectService = container.resolve(ProjectService);

export function ForumHeader({ post }: ForumHeaderProps) {
	const navigate = useNavigate();
	const [project, setProject] = useState<ProjectResponseResource | null>(null);

	const onClickReturn = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	useEffect(() => {
		projectService.getProjectById(post.project.id).then((project) => setProject(project));
	}, [post]);

	return (
		<div className={classes["root"]}>
			<div className={classes["return-button-container"]}>
				<Button variant={EButtonVariant.TEXT} color={EButtonColor.PRIMARY} startIcon={<ArrowLeftIcon />} onClick={onClickReturn}>
					<Typography type="p" weight="bold" color="inherit">
						{I18n.trslt(I18n.asset.pages.post.return)}
					</Typography>
				</Button>
			</div>
			<header className="forum-header">
				<div className="forum-header__content">
					<div className={classes["author"]}>
						<div className={classes["avatar"]}>{<img src={project?.poster?.file?.url || DefaultAvatar} alt="Author" className={classes["avatar"]} />}</div>
						<div className={classes["publication"]}>
							<div className={classes["by"]}>
								<Typography type="h5" weight="bold">
									{post.User.id === post.project.author.id ? I18n.trslt(I18n.asset.common.crew) + " " + post.project.title : getUserName(post.User)}
								</Typography>
							</div>
							<div className={classes["at"]}>
								<Typography type="p" weight="regular" size="small" color="inherit">
									{formatDate(post.createdAt ?? "")}
								</Typography>
							</div>
						</div>
					</div>
					<h1 className={classNames("forum-header__title", classes["pre-line"])}>{post.title}</h1>

					{post.subjects.map((subject) => {
						return (
							<div className="forum-header__subject-container">
								<h3 className="forum-header__subject-title">{subject.title}</h3>
								<div className="forum-header__characteristics">
									{subject.tags.map(({ key, value }) => (
										<div key={key} className="forum-header__characteristics-item">
											<span className="forum-header__characteristics-item-label">{key}:</span>
											<span className="forum-header__characteristics-item-value">{value}</span>
										</div>
									))}
								</div>
								<p className="forum-header__description">{subject.description}</p>
							</div>
						);
					})}
				</div>
			</header>
		</div>
	);
}

const getUserName = (user: Pick<UserPublicResponseResource, "firstName" | "lastName">) => {
	const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
	return `${capitalize(user.firstName)} ${capitalize(user.lastName)}`;
};
