import { match } from "ts-pattern";
import DefaultAvatar from "assets/images/default-avatar.svg";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import DimProjectLeader from "components/pages/FeaturedProjects/Dim/ProjectDetails/ThisProjectLeader";
import MalgreMoiProjectLeader from "components/pages/FeaturedProjects/MalgreMoi/ProjectDetails/ThisProjectLeader";
import FriendsProjectLeader from "components/pages/FeaturedProjects/Friends/ProjectDetails/ThisProjectLeader";
import GrandSautProjectLeader from "components/pages/FeaturedProjects/GrandSaut/ProjectDetails/ThisProjectLeader";
import KDanseProjectLeader from "components/pages/FeaturedProjects/KDanse/ProjectDetails/ThisProjectLeader";
import LAttenteProjectLeader from "components/pages/FeaturedProjects/LAttente/ProjectDetails/ThisProjectLeader";
import SnowVisionProjectLeader from "components/pages/FeaturedProjects/SnowVision/ProjectDetails/ThisProjectLeader";
import BataillonSilencieuxProjectLeader from "components/pages/FeaturedProjects/BataillonSilencieux/ProjectDetails/ThisProjectLeader";
import RajeunirOuMourirProjectLeader from "components/pages/FeaturedProjects/RajeunirOuMourir/ProjectDetails/ThisProjectLeader";
import PotusProjectLeader from "components/pages/FeaturedProjects/Potus/ProjectDetails/ThisProjectLeader";
import ClubVipProjectLeader from "components/pages/FeaturedProjects/ClubVip/ProjectDetails/ThisProjectLeader";

import classes from "./classes.module.scss";

type IProps = {
	project: ProjectResponseResource;
};

export default function ProjectLeaderBadge(props: IProps) {
	return (
		<div className={classes["root"]}>
			{match(props.project.componentName)
				.with("Dim", () => <DimProjectLeader />)
				.with("MalgreMoi", () => <MalgreMoiProjectLeader />)
				.with("Friends", () => <FriendsProjectLeader />)
				.with("GrandSaut", () => <GrandSautProjectLeader />)
				.with("KDanse", () => <KDanseProjectLeader />)
				.with("LAttente", () => <LAttenteProjectLeader />)
				.with("SnowVision", () => <SnowVisionProjectLeader />)
				.with("BataillonSilencieux", () => <BataillonSilencieuxProjectLeader />)
				.with("RajeunirOuMourir", () => <RajeunirOuMourirProjectLeader />)
				.with("Potus", () => <PotusProjectLeader />)
				.with("ClubVip", () => <ClubVipProjectLeader />)
				.otherwise(() => (
					<DefaultProjectLeaderBadge {...props} />
				))}
		</div>
	);
}

function DefaultProjectLeaderBadge(props: IProps) {
	const avatar = props.project.author.avatar?.url ?? DefaultAvatar;
	const author = `${props.project.author.firstName} ${props.project.author.lastName}`;

	return (
		<>
			<Typography type="p" size="medium" weight="medium">
				{I18n.trslt(I18n.asset.component.featured_project.project_leader)}
			</Typography>

			<div className={classes["avatar"]}>
				<img src={avatar} alt="Avatar" className={classes["avatar"]} />
			</div>

			<Typography type="p" size="small" weight="bold" fontFamily="secondary">
				{author}
			</Typography>
		</>
	);
}
