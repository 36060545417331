import PageTemplate from "components/PageTemplate";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import FrameDesign from "assets/images/frame-design.png";
import Typography from "components/elements/Typography";
import ProjectCard from "components/materials/ProjectCard";
import FeaturedProject from "components/pages/Home/FeaturedProject";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { useState, useCallback, useEffect, Fragment } from "react";
import { container } from "tsyringe";
import ProjectService from "services/ProjectService";

const projectService = container.resolve(ProjectService);

const customOrder = ["ClubVip", "Potus", "RajeunirOuMourir", "BataillonSilencieux", "SnowVision", "Dim", "GrandSaut", "MalgreMoi", "Friends", "KDanse", "LAttente"];

const sortProjects = (projects: ProjectResponseResource[]) => {
	return projects.sort((a, b) => {
		const indexA = a.componentName ? customOrder.indexOf(a.componentName) : -1;
		const indexB = b.componentName ? customOrder.indexOf(b.componentName) : -1;

		if (indexA !== -1 && indexB !== -1) {
			return indexA - indexB;
		}
		if (indexA !== -1) {
			return -1;
		}
		if (indexB !== -1) {
			return 1;
		}
		if (a.featured && !b.featured) {
			return -1;
		}
		if (!a.featured && b.featured) {
			return 1;
		}
		return 0;
	});
};

export default function Home() {
	const [projects, setProjects] = useState<ProjectResponseResource[]>([]);

	const fetchProjects = useCallback(() => {
		projectService.getProjects().then((projects) => setProjects(sortProjects(projects)));
	}, []);

	useEffect(() => {
		fetchProjects();
	}, [fetchProjects]);

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.projects.page_title)} mainSectionClassName={classes["root"]}>
			<Typography type="h1" weight="bold" className={classes["title"]}>
				{I18n.trslt(I18n.asset.pages.projects.title)}
				<Typography type="span" weight="bold" color="secondary" className={classes["inside-title"]}>
					&nbsp;{I18n.trslt(I18n.asset.pages.projects.inside_title)}
				</Typography>
			</Typography>

			<div className={classes["body-container"]}>
				<img src={FrameDesign} alt="Frame design" className={classes["frame-image"]} />

				<div className={classes["projects"]}>
					<div className={classes["project-list"]}>
						{projects.map((project, index) => (
							<Fragment key={project.id}>
								{project.featured ? <FeaturedProject project={project} /> : <ProjectCard project={project} />}
								{index < projects.length - 1 && <div className={classes["separator"]} />}
							</Fragment>
						))}
					</div>
				</div>
			</div>
		</PageTemplate>
	);
}
