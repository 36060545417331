import { container, singleton } from "tsyringe";
import UserApi from "api/clapaction/UserApi";
import AdminUserApi from "api/clapaction/admin/UserApi";
import UserAccountInfoResponseResource from "common/resources/User/UserAccountInfoResponseResource";
import UserAccountInfoUpdateRequestResource from "common/resources/User/UserAccountInfoUpdateRequestResource";
import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import UserServiceClassToken from "common/resources/injectables/UserServiceClassToken";
import toValidationError from "utils/toValidationError";

@singleton()
export default class UserService implements UserServiceClassToken {
	private api: UserApi = container.resolve(UserApi);
	private adminApi: AdminUserApi = container.resolve(AdminUserApi);

	public async isUniqueEmail(_email: string) {
		return true;
	}

	public async isEmailExist(_email: string) {
		return true;
	}

	public async isListEmailExist(_emails: string[]) {
		return true;
	}

	public async exists(_id: string) {
		return true;
	}

	public async checkPassword(_email: string, _password: string) {
		return true;
	}

	public async isPinCodeValid(_email: string, _pinCode: string) {
		return true;
	}

	public async getProfile(userId: string): Promise<UserPublicResponseResource> {
		return this.api.getProfile(userId).catch(toValidationError);
	}

	public async getProfilePictureUrl(userId: string): Promise<string | null> {
		return this.getProfile(userId).then((user) => (user.avatar ? this.api.getProfilePictureUrl(user.id) : null));
	}

	public async getAccount(): Promise<UserAccountInfoResponseResource> {
		return this.api.getAccount().catch(toValidationError);
	}

	public async updateAccount(user: UserAccountInfoUpdateRequestResource, profilePicture?: File | undefined): Promise<UserAccountInfoResponseResource> {
		return this.api.updateAccount(user, profilePicture).catch(toValidationError);
	}
}
