import { singleton } from "tsyringe";
import UserAccountInfoResponseResource from "common/resources/User/UserAccountInfoResponseResource";
import UserAccountInfoUpdateRequestResource from "common/resources/User/UserAccountInfoUpdateRequestResource";
import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import BaseApi from "../BaseApi";

@singleton()
export default class UserApi extends BaseApi {
	private baseurl = `${this.apiUrl}/users`;

	public async getProfile(userId: string): Promise<UserPublicResponseResource> {
		return this.getRequest<UserPublicResponseResource>(`${this.baseurl}/${userId}`);
	}

	public getProfilePictureUrl(userId: string): string {
		return `${this.baseurl}/${userId}/avatar`;
	}

	public async getAccount() {
		const url = `${this.baseurl}/account`;
		return this.getRequest<UserAccountInfoResponseResource>(url);
	}

	public async updateAccount(user: UserAccountInfoUpdateRequestResource, profilePicture?: File) {
		const url = `${this.baseurl}/account`;

		const formData = new FormData();
		if (profilePicture) {
			formData.append("file", profilePicture);
		}
		Object.entries(user).forEach(([key, value]) => {
			if (key === "phoneNumber" && !value) return;
			formData.append(key, value);
		});

		return this.putRequestFormdata<UserAccountInfoResponseResource>(url, formData);
	}
}
