import ProjectApi from "api/clapaction/ProjectApi";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import { EProjectPurpose } from "common/enums/ProjectPurpose";
import { EProjectReason } from "common/enums/ProjectReason";
import ProjectCreateRequestResource from "common/resources/Project/ProjectCreateRequestResource";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import ProjectReponseResource from "common/resources/Project/ProjectResponseResource";
import ProjectUpdateRequestResource from "common/resources/Project/ProjectUpdateRequestResource";
import ProjectUpdateSocialsRequestResource from "common/resources/Project/ProjectUpdateSocialsRequestResource";
import QuestionResponseResource from "common/resources/Question/QuestionResponseResource";
import ProjectServiceClassToken from "common/resources/injectables/ProjectServiceClassToken";
import { container, singleton } from "tsyringe";
import { Pagination } from "utils/Pagination";
import toValidationError from "utils/toValidationError";

@singleton()
export default class ProjectService implements ProjectServiceClassToken {
	private projectApi: ProjectApi = container.resolve(ProjectApi);

	public async exists(_id: string): Promise<boolean> {
		return this.getProjectById(_id)
			.then(() => true)
			.catch(() => false);
	}

	public async getProjects(): Promise<ProjectReponseResource[]> {
		return this.projectApi.getProjects();
	}

	public async getProjectActiveQuestions(project: Pick<ProjectReponseResource, "id">): Promise<QuestionResponseResource[] | null> {
		return this.projectApi.getProjectActiveQuestions(project.id);
	}

	public async getProjectLastActiveQuestion(project: Pick<ProjectReponseResource, "id">): Promise<QuestionResponseResource | null> {
		return this.projectApi.getProjectActiveQuestions(project.id, { take: 1, skip: 0 }).then((questions) => questions[0] || null);
	}

	public async getProjectLastInactiveQuestion(project: Pick<ProjectReponseResource, "id">): Promise<QuestionResponseResource | null> {
		return this.projectApi.getProjectInactiveQuestions(project.id, { take: 1, skip: 0 }).then((questions) => questions[0] || null);
	}


	public async getProjectPreviousQuestion(project: Pick<ProjectReponseResource, "id">): Promise<QuestionResponseResource | null> {
		return this.projectApi.getProjectQuestions(project.id, { take: 1, skip: 1 }).then((questions) => questions[0] || null);
	}

	public async getProjectById(id: string): Promise<ProjectReponseResource> {
		return this.projectApi.getProjectById(id);
	}

	public async createProject(project: ProjectCreateRequestResource, files: { scenario?: File; cv?: File }): Promise<ProjectReponseResource> {
		return this.projectApi.createProject(project, files).catch(toValidationError);
	}

	public async updateProject(id: string, project: ProjectUpdateRequestResource, files: { scenario?: File; cv?: File }): Promise<ProjectReponseResource> {
		return this.projectApi.updateProject(id, project, files).catch(toValidationError);
	}

	public async likeProject(id: string): Promise<{ liked: boolean }> {
		return this.projectApi.likeProject(id).catch(toValidationError);
	}

	public async updateProjectSocials(id: string, project: ProjectUpdateSocialsRequestResource): Promise<ProjectReponseResource> {
		return this.projectApi.updateProjectSocials(id, project).catch(toValidationError);
	}

	public async createAnchor(projectId: string): Promise<void> {
		return this.projectApi.createAnchor(projectId).catch(toValidationError);
	}

	public async getMyProjects(pagination?: Pagination): Promise<ProjectReponseResource[]> {
		return this.projectApi.getMyProjects(pagination);
	}

	public async countMyProjects(): Promise<number> {
		return this.projectApi
			.countMyProjects()
			.then((res) => res.total)
			.catch(toValidationError);
	}

	public async changeGeneratedPosterImage(projectId: string): Promise<{ discarded: boolean } | { next: true }> {
		return this.projectApi.changeGeneratedPosterImage(projectId);
	}

	public async canDiscardGeneratedPosterImage(projectId: string): Promise<{ canDiscard: boolean }> {
		return this.projectApi.canDiscardGeneratedPosterImage(projectId);
	}

	public async downloadAnchorProof(projectId: string) {
		return this.projectApi.downloadAnchorProof(projectId);
	}

	public async isLinkedInUrlValid(url: string): Promise<boolean> {
		const regex = /^(https?:\/\/)?([a-zA-Z]{2,3}\.)?linkedin\.com\/in\/[\p{L}0-9-.]{1,100}\/?$/u;

		return regex.test(url);
	}

	public static isPurposeOnlyAnchor = (project: Pick<ProjectResponseResource, "purpose" | "reason">) => {
		if (project.purpose.length === 0 && project.reason === EProjectReason.PROTECT) return true;
		if (project.purpose.length === 1 && project.purpose?.[0]?.purpose === EProjectPurpose.AUTHENTICITY_PROTECTION) return true;

		return false;
	};

	public static isAccepted = (project: Pick<ProjectResponseResource, "purpose" | "reason" | "status">) => {
		return !ProjectService.isPurposeOnlyAnchor(project) && project.status === EProjectStatus.ACCEPTED;
	};
}
