import React, { useCallback, useContext, useEffect, useState } from "react";
import { ChevronDown, ChevronUp, Heart, MessageCircle, Trash2 } from "lucide-react";
import { format } from "date-fns";
import { container } from "tsyringe";
import DefaultAvatar from "assets/images/default-avatar.svg";
import I18n from "components/materials/I18n";
import { dateFnsLocal } from "components/materials/I18n/I18nStore";
import CommentCreateRequestResource from "common/resources/Comment/CommentCreateRequestResource";
import CommentResponseResource from "common/resources/Comment/CommentResponseResource";
import PostResponseResource from "common/resources/Post/PostResponseResource";
import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import Typography from "components/elements/Typography";
import { UserContext } from "contexts/User";
import CommentService from "services/CommentService";
import UserService from "services/UserService";
import classes from "./classes.module.scss";

interface CommentCardProps {
	post: PostResponseResource;
	comment: CommentResponseResource;
	depth?: number;
	onDelete: (commentId: string) => Promise<void>;
}

const commentService = container.resolve(CommentService);
const userService = container.resolve(UserService);

const getUserName = (user: Pick<UserPublicResponseResource, "firstName" | "lastName">) => {
	const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
	return `${capitalize(user.firstName)} ${capitalize(user.lastName)}`;
};

const formatDate = (date: Date) => {
	return format(new Date(date), "PPPp", { locale: dateFnsLocal[I18n.getLocale()] });
};

export function CommentCard(props: CommentCardProps) {
	const { user } = useContext(UserContext);
	const { avatar: authorAvatar } = useAvatar(props.comment.author.id);
	const [comment, setComment] = useState<CommentResponseResource>(props.comment);
	const [isReplying, setIsReplying] = useState(false);
	const [replyText, setReplyText] = useState("");
	const [isCollapsed, setIsCollapsed] = useState(true);

	const handleLike = useCallback(async () => {
		await commentService.likeComment(comment.id);
		const commentResponse = await commentService.getComment(comment.id);
		setComment(commentResponse);
	}, [comment]);

	const handleSubmitReply = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!replyText.trim()) return;

		const newCommentRequest = CommentCreateRequestResource.hydrate<CommentCreateRequestResource>({
			text: replyText,
			parentId: comment.id,
		});

		await commentService.createComment(props.post.id, newCommentRequest);
		const commentResponse = await commentService.getComment(comment.id);
		setComment(commentResponse);
		setReplyText("");
		setIsReplying(false);
		setIsCollapsed(false);
	};

	useEffect(() => {
		setComment(props.comment);
	}, [props.comment]);

	const toggleCollapse = useCallback(() => {
		setIsCollapsed(!isCollapsed);
	}, [isCollapsed]);

	const maxDepth = 4;
	const depth = props.depth ?? 0;
	const shouldAllowMoreReplies = depth < maxDepth;
	const hasReplies = comment.replies.length > 0;
	const canDelete = user?.id === props.post.User.id || user?.id === comment.authorId;

	return (
		<div className={classes["root"]}>
			<div className={depth > 0 ? "comment-card__container" : ""}>
				<div className="comment-card__content">
					<div className="comment-card__header">
						<div className={classes["author"]}>
							<div className={classes["avatar-container"]}>
								<div className={classes["avatar"]}>{<img src={authorAvatar} alt="Author" className={classes["avatar"]} />}</div>
							</div>
							<div className={classes["publication"]}>
								<div className={classes["by"]}>
									<Typography type="h5" weight="bold">
										{comment.authorId === props.post.project.author.id
											? I18n.trslt(I18n.asset.common.crew) + " " + props.post.project.title
											: getUserName(props.comment.author)}
									</Typography>
								</div>
								<div className={classes["at"]}>
									<Typography type="p" weight="regular" size="small" color="inherit">
										{formatDate(props.comment.createdAt ?? "")}
									</Typography>
								</div>
							</div>
						</div>
						<div className="comment-card__actions">
							<button
								onClick={handleLike}
								className={`comment-card__like-button ${comment.likes.map((like) => like.userId).includes(user!.id) ? "comment-card__like-button--active" : ""}`}>
								<Heart size={14} className={comment.likes.map((like) => like.userId).includes(user!.id) ? "fill-current" : ""} />
								<span>{comment.likes.length}</span>
							</button>
							{canDelete && (
								<button
									onClick={() => {
										props.onDelete(comment.id);
									}}
									className="comment-card__delete-button"
									title={I18n.trslt(I18n.asset.pages.post.comment.reply.delete)}>
									<Trash2 size={16} />
								</button>
							)}
						</div>
					</div>

					<p className="comment-card__text">{comment.text}</p>

					{comment.imageUrl && <img src={comment.imageUrl} alt="Uploaded content" className="comment-card__image" />}

					<div className="comment-card__footer">
						{shouldAllowMoreReplies && (
							<button onClick={() => setIsReplying(!isReplying)} className="comment-card__reply-button">
								<MessageCircle size={16} />
								{I18n.trslt(I18n.asset.pages.post.comment.reply.submit)}
							</button>
						)}
						{hasReplies && (
							<button onClick={toggleCollapse} className="comment-card__collapse-button">
								{isCollapsed ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
								{comment.replies.length}{" "}
								{comment.replies.length > 1
									? I18n.trslt(I18n.asset.pages.post.comment.reply.many_replies)
									: I18n.trslt(I18n.asset.pages.post.comment.reply.single_reply)}
							</button>
						)}
					</div>

					{isReplying && (
						<form onSubmit={handleSubmitReply} className="comment-card__reply-form">
							<textarea
								value={replyText}
								onChange={(e) => setReplyText(e.target.value)}
								placeholder={I18n.trslt(I18n.asset.pages.post.comment.reply.your_reply)}
								rows={3}
								maxLength={1000}
							/>
							<div className="comment-card__reply-form-actions">
								<button type="button" onClick={() => setIsReplying(false)} className="comment-card__reply-form-cancel">
									{I18n.trslt(I18n.asset.common.cancel)}
								</button>
								<button type="submit" disabled={!replyText.trim()} className="comment-card__reply-form-submit">
									{I18n.trslt(I18n.asset.pages.post.comment.reply.submit)}
								</button>
							</div>
						</form>
					)}
				</div>

				{!isCollapsed && comment.replies.length > 0 && (
					<div className="comment-card__replies">
						{comment.replies.map((reply) => (
							<CommentCard key={reply.id} comment={reply} depth={depth + 1} post={props.post} onDelete={props.onDelete} />
						))}
					</div>
				)}
			</div>
		</div>
	);
}

const useAvatar = (userId: string) => {
	const [avatar, setAvatar] = useState<string>(DefaultAvatar);

	useEffect(() => {
		userService.getProfilePictureUrl(userId).then((url) => setAvatar(url ?? DefaultAvatar));
	}, [userId]);

	return { avatar };
};
