import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import ClapActionLogo from "assets/images/logo-clapaction.png";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import Typography from "components/elements/Typography";
import { HeaderContext } from "contexts/Header";
import { UserContext } from "contexts/User";
import NotConnectedHeader from "./NotConnectedHeader";
import ConnectedHeader from "./ConnectedHeader";
import I18n from "../I18n";
import I18nStore from "../I18n/I18nStore";
import ResponsiveMenu from "./ResponsiveMenu";
import LangSelector from "./LangSelector";
import ComingSoon from "./ComingSoon";
import classes from "./classes.module.scss";

const modules = container.resolve(ModuleConfig).get().modules;

export default function Header() {
	const { user } = useContext(UserContext);
	const { isComingSoonEnabled, isComingSoonOpen, setIsComingSoonOpen } = useContext(HeaderContext);

	const getSubscribeUrl = useCallback(() => {
		return match(I18nStore.getInstance().lang)
			.with("fr", () => "https://www.clapaction.com/espace-pro-createurs")
			.with("en", () => "https://www.clapaction.com/en/pro-and-creators-space")
			.exhaustive();
	}, []);

	const getCallForProjectUrl = useCallback(() => {
		return match(I18nStore.getInstance().lang)
			.with("fr", () => "https://www.clapaction.com/appel-a-projet")
			.with("en", () => "https://www.clapaction.com/en/call-for-projects")
			.exhaustive();
	}, []);

	return (
		<>
			<header className={classes["root"]}>
				<div className={classes["content"]}>
					<nav>
						<Link to={modules.pages.Home.props.path} className={classes["logo-container"]}>
							<img src={ClapActionLogo} alt="clapaction logo" className={classes["logo"]} />
						</Link>

						<div className={classes["links-container"]}>
							<Link to={modules.pages.Home.props.path} className={classes["link"]}>
								<Typography type="span" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.header.projects)}
								</Typography>
							</Link>

							<Link to={getSubscribeUrl()} target="_blank" rel="noreferrer" className={classes["link"]}>
								<Typography type="span" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.header.subscribe)}
								</Typography>
							</Link>

							<Link to={getCallForProjectUrl()} target="_blank" rel="noreferrer" className={classes["link"]}>
								<Typography type="span" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.header.call_for_project)}
								</Typography>
							</Link>

							<Link to={modules.pages.Contact.props.path} className={classes["link"]}>
								<Typography type="span" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.header.contact)}
								</Typography>
							</Link>

							<LangSelector />

							<div className={classes["extra-header"]}>
								{!user && <NotConnectedHeader />}
								{user && <ConnectedHeader user={user} />}
							</div>

							<ResponsiveMenu user={user} />
						</div>
					</nav>
				</div>
			</header>
			{isComingSoonEnabled && isComingSoonOpen && <ComingSoon onClose={() => setIsComingSoonOpen(false)} />}
		</>
	);
}
