import { singleton } from "tsyringe";
import BaseApi from "../BaseApi";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import ProjectCreateRequestResource from "common/resources/Project/ProjectCreateRequestResource";
import QuestionResponseResource from "common/resources/Question/QuestionResponseResource";
import { Pagination } from "utils/Pagination";
import AnchorResponseResource from "common/resources/Anchor/AnchorResponseResource";
import ProjectUpdateRequestResource from "common/resources/Project/ProjectUpdateRequestResource";
import ProjectUpdateSocialsRequestResource from "common/resources/Project/ProjectUpdateSocialsRequestResource";

@singleton()
export default class ProjectApi extends BaseApi {
	private baseurl = `${this.apiUrl}/projects`;

	public async getProjects() {
		return this.getRequest<ProjectResponseResource[]>(`${this.baseurl}`);
	}

	public async getMyProjects(pagination?: Pagination) {
		const query = pagination ? `?_take=${pagination.take}&_skip=${pagination.skip}` : "";
		return this.getRequest<ProjectResponseResource[]>(`${this.baseurl}/my-projects${query}`);
	}

	public async countMyProjects() {
		return this.getRequest<{ total: number }>(`${this.baseurl}/my-projects/count`);
	}

	public async getProjectById(projectId: string) {
		return this.getRequest<ProjectResponseResource>(`${this.baseurl}/${projectId}`);
	}

	public async createProject(project: ProjectCreateRequestResource, files: { scenario?: File; cv?: File }) {
		const formData = new FormData();

		formData.append("title", project.title);
		formData.append("pitch", project.pitch);
		formData.append("format", project.format);
		formData.append("theme", project.theme);
		formData.append("lang", project.lang);

		project.purposes.forEach((purpose) => {
			formData.append("purposes", purpose);
		});

		if (project.discord) formData.append("discord", project.discord);
		if (project.instagram) formData.append("instagram", project.instagram);
		if (project.x) formData.append("x", project.x);
		if (project.linkedin) formData.append("linkedin", project.linkedin);

		if (files.scenario) formData.append("scenario", files.scenario);
		if (files.cv) formData.append("cv", files.cv);

		return this.postRequestFormdata<ProjectResponseResource>(`${this.baseurl}`, formData);
	}

	public async updateProject(projectId: string, project: ProjectUpdateRequestResource, files: { scenario?: File; cv?: File }) {
		const formData = new FormData();

		formData.append("title", project.title);
		formData.append("pitch", project.pitch);
		formData.append("format", project.format);
		formData.append("theme", project.theme);
		formData.append("lang", project.lang);

		project.purposes.forEach((purpose) => {
			formData.append("purposes", purpose);
		});

		if (project.discord) formData.append("discord", project.discord);
		if (project.instagram) formData.append("instagram", project.instagram);
		if (project.x) formData.append("x", project.x);
		if (project.linkedin) formData.append("linkedin", project.linkedin);

		if (files.scenario) formData.append("scenario", files.scenario);
		if (files.cv) formData.append("cv", files.cv);

		return this.putRequestFormdata<ProjectResponseResource>(`${this.baseurl}/${projectId}`, formData);
	}

	public async likeProject(projectId: string) {
		const url = `${this.baseurl}/${projectId}/like`;
		return this.postRequest<{ liked: boolean }>(url);
	}

	public async updateProjectSocials(projectId: string, project: ProjectUpdateSocialsRequestResource) {
		return this.putRequest<ProjectResponseResource>(`${this.baseurl}/${projectId}/socials`, { ...project });
	}

	public async getProjectQuestions(projectId: string, pagination?: Pagination) {
		const query = pagination ? `?_take=${pagination.take}&_skip=${pagination.skip}` : "";

		return this.getRequest<QuestionResponseResource[]>(`${this.baseurl}/${projectId}/questions${query}`);
	}

	public async getProjectActiveQuestions(projectId: string, pagination?: Pagination) {
		const query = pagination ? `?_take=${pagination.take}&_skip=${pagination.skip}` : "";

		return this.getRequest<QuestionResponseResource[]>(`${this.baseurl}/${projectId}/questions/active${query}`);
	}

	public async getProjectInactiveQuestions(projectId: string, pagination?: Pagination) {
		const query = pagination ? `?_take=${pagination.take}&_skip=${pagination.skip}` : "";

		return this.getRequest<QuestionResponseResource[]>(`${this.baseurl}/${projectId}/questions/inactive${query}`);
	}

	public async changeGeneratedPosterImage(projectId: string) {
		return this.postRequest<{ discarded: boolean } | { next: true }>(`${this.baseurl}/${projectId}/poster/change`);
	}

	public async canDiscardGeneratedPosterImage(projectId: string) {
		return this.getRequest<{ canDiscard: boolean }>(`${this.baseurl}/${projectId}/poster/can-discard`);
	}

	public async createAnchor(projectId: string) {
		return this.postRequest<AnchorResponseResource>(`${this.baseurl}/${projectId}/anchor`);
	}

	public async downloadAnchorProof(projectId: string) {
		return this.getBlobRequest(`${this.baseurl}/${projectId}/anchor/download`);
	}
}
